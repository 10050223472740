const body = document.body;
const nav = document.querySelector('.js-nav');
const navTrigger = document.querySelector('.js-nav-trigger');
const navClose = document.querySelector('.js-nav-close');

const primaryTriggers = document.querySelectorAll('[data-primary-nav="trigger"]'),
      primaryTarget   = '[data-primary-nav="target"]'; 

const _open = '_is-open';

navTrigger.addEventListener('click', function(event) {
    event.preventDefault();

    body.classList.add('has-open-nav');
    nav.classList.add('is-active');
});

navClose.addEventListener('click', function(event) {
    event.preventDefault();

    body.classList.remove('has-open-nav');
    nav.classList.remove('is-active');
});

if ( primaryTriggers ) {

    const hasTouch = () => {
        return ( 'ontouchstart' in window ) || 
            ( navigator.maxTouchPoints > 0 ) || 
            ( navigator.msMaxTouchPoints > 0 );
    }

    primaryTriggers.forEach(trigger => {

        const handler = (e) => {

            let action = e.type, // e.g. touchstart, mouseover, click
                target = trigger.parentNode.closest('li').querySelector(primaryTarget);
            
            if ( !target || !hasTouch() ) return; 

            e.preventDefault();

            // Toggle submenu based on state;
            // Enables opacity and pointer events via css
            target.classList.toggle(_open);
            
        }
        
        const options = {
            click: {
                handler,
            },
            touchstart: {
                handler,
            },
            mouseover: {
                handler,
            }
        };

        Object.keys(options).forEach(key => trigger.addEventListener(key, handler));
              
    });

}


