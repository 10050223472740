
const _bookmark   = 'data-bookmark',
      bookmarkEls = document.querySelectorAll(`[${_bookmark}="trigger"]`);

if ( bookmarkEls ) {

    bookmarkEls.forEach(trigger => {

        trigger.addEventListener('click', (e) => {

            e.preventDefault();

            let el = e.target.hasAttribute(_bookmark) ? e.target : e.target.closest(_bookmark),
                id = el.getAttribute('href').replace('#',''),
                target = document.getElementById(id);

            if ( target ) {
                target.scrollIntoView({ behavior: 'smooth', block: 'start' });        
            }

        });
    
    });
   
}
    