import tingle from "tingle.js";
import Swiper from 'swiper/bundle';
import { Grid, Navigation, Pagination } from 'swiper/modules';

Swiper.use([Grid, Navigation, Pagination]);

const sliderBaseOptions = {
    slidesPerView: 1,
    loop: true,
	autoHeight: true,
    spaceBetween: 10,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
	on: {
        init: function (swiper) {
            if (swiper.slides.length === 1) {
                this.el.classList.add('one-item');
            } else {
                this.el.classList.remove('one-item');
            }
        }
    }
};

const personModal = new tingle.modal({
	cssClass: ['person-modal'],
	onOpen: function() {
        
		const modalCloseButtons = this.modal.querySelectorAll('.js-modal-close');

		// Enable a friendly hotlink
		let el = this.modal.querySelector('[data-modal-id]'),
			id = el.dataset.modalId;

		history.pushState({}, 'Bio', `?bio=${id}`);

		modalCloseButtons.forEach(closeButton => {
			closeButton.addEventListener('click', function (event) {
				event.preventDefault();

				// Remove param
				history.replaceState({}, '', window.location.pathname);

				personModal.close();

			})
		})
    },
});

const transactionModal = new tingle.modal({
	cssClass: ['person-modal'],
	onOpen: function() {
        const modalCloseButtons = this.modal.querySelectorAll('.js-modal-close');

		// Enable a friendly hotlink
		let el = this.modal.querySelector('[data-modal-id]'),
			id = el.dataset.modalId;
		
		history.pushState({}, 'Transaction', `?transaction=${id}`);

		modalCloseButtons.forEach(closeButton => {
			closeButton.addEventListener('click', function (event) {
				event.preventDefault();

				// Remove param
				history.replaceState({}, '', window.location.pathname);

				transactionModal.close();
			})
		});

		const modalLogosSlides = this.modal.querySelectorAll('.js-slider-logos');

		modalLogosSlides.forEach(logosSlider => {
			new Swiper(logosSlider, sliderBaseOptions);
		});
    },
});

const persons = document.querySelectorAll('.js-tile-bio');

function fetchModalContent(url, modal) {
	fetch(url).then(res => res.text()).then(data => {
		modal.setContent(data);
		modal.open();
	})
}

persons.forEach(person => {
	person.addEventListener('click', function (event) {
		event.preventDefault();
		
		const modalUrl = this.href;
		fetchModalContent(modalUrl, personModal);
	})
});

// Check for hotlinked person
let params = new URL(document.location).searchParams;

if ( params && params.get('bio') ) {

	let name = params.get('bio');

	const modalTrigger = document.querySelector(`[data-modal-trigger="${name}"]`);

	if ( modalTrigger ) {
		const modalUrl = modalTrigger.href;
		fetchModalContent(modalUrl, personModal);
	}

}
// Check for hotlinked transaction
if ( params && params.get('transaction') ) {

	let q = params.get('transaction');

	const modalTrigger = document.querySelector(`[data-modal-trigger="${q}"]`);
	
	if ( modalTrigger ) {
		const modalUrl   = modalTrigger.href;
		fetchModalContent(modalUrl, transactionModal);
	}

}

// Bio modal, with dynamic trigger enabled
// (when search is initiated)
document.addEventListener('click', (e) => {

	const dynamicTrigger = e.target.closest('.js-tile-bio'); 

	if ( dynamicTrigger ) {

		e.preventDefault();

		const modalUrl = dynamicTrigger.href;

		fetchModalContent(modalUrl,personModal);

	}

}); 

// Transaction modal, with dynamic trigger enabled
// (when search is initiated)
document.addEventListener('click', (e) => {

	const dynamicTrigger = e.target.closest('.js-modal-transaction-trigger') ; 

	if ( dynamicTrigger ) {

		e.preventDefault();

		const modalUrl = dynamicTrigger.href;

		fetchModalContent(modalUrl, transactionModal);

	}

}); 